/* general */
:root {
  --nav-green: #ebf3e8;
  --green: #5d9846;
  --white: #f7f7f7;
  --black: #040404;
}

body {
  background-color: var(--white);
}

h1,
h2 {
  font-weight: 900;
}

h1 {
  font-size: 47px;
  margin-bottom: 35px;
}

h2 {
  font-size: 25px;
}

p {
  margin: 0;
  font-size: 16px;
}

.logo-text {
  /* font-size: 27px; */
  text-transform: uppercase;
  /* margin: -17px; */
  font-weight: 100;
}

/* for links */
button {
  background: #00000000;
  border: 0;
}

button a {
  width: fit-content;
  display: block;
  margin: auto;
  padding: 1rem 1.25rem;
  font-family: sans-serif;
  font-size: 15px;
  text-decoration: none;
  color: var(--black);
  position: relative;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
}

button a:after {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  bottom: 0;
  right: 1.25rem;
  left: 1.25rem;
  background-color: var(--black);
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  transform-origin: bottom center;
  z-index: -1;
}

button a:hover {
  color: var(--white);
}

button a:hover:after {
  right: 0;
  left: 0;
  height: 100%;
}

a {
  color: var(--black);
  text-decoration: none;
}

.pre-heading {
  color: var(--green);
  text-transform: uppercase;
}

.pre-heading::before,
.pre-heading::after {
  content: "-";
}

.text-center {
  text-align: center;
}

/* nav */
section.nav {
  background: transparent;
  position: absolute;
  position: fixed;
  width: 100%;
  z-index: 998;
  transition: all 300ms ease-in-out 0s;
}
section.nav.active {
  background: #f7f7f7;
  box-shadow: #717171 0 -9px 20px 0px;
}
section.nav.active a,
section.nav.active .dropdown button {
  color: #000000;
}

/* nav dropdown */
.dropdown-content div div a {
  @apply px-5 !important;
}

/* body{
  background: #0a0a23;
  min-height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
} */

section.nav a {
  display: block;
}

section.nav .dropdown-content {
  @apply -top-[75vh] animate-medium-opacity-reverse transition-all ease-in-out duration-500 rounded-lg;
}

section.nav.active .dropdown-content.visible {
  @apply top-[145%] animate-medium-opacity;
}

section.nav .dropdown {
  display: inline-block;
  position: relative;
}

section.nav a,
section.nav .dropdown button {
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  padding: 10px 45px;
  /* margin: 0 45px; */
  cursor: pointer;
}

section.nav a.mt-4.font-bold {
  padding: 10px 30px;
}

section.nav a.mt-4.font-bold img {
  object-fit: contain !important;
}
section.nav a:hover,
section.nav .dropdown button:hover {
  background-color: var(--nav-green);
  transition: all 300ms ease-in-out;
}

/* section.nav .dropdown-options {
  height: 0;
  position: relative;
  overflow: auto;
  z-index: 5;
  border-radius: 5px;
  transition: height 300ms ease-in-out;
}

section.nav .dropdown:hover .dropdown-options {
  height: 25vh;
}

section.nav .dropdown-options a {
  display: block;
  color: #000000;
  padding: 5px;
  text-decoration: none;
  padding: 10px;
  background-color: var(--white);
}

.landing section.nav .dropdown-options a,
.achieve section.nav .dropdown-options a {
  background-color: #00000000;
}

section.nav .dropdown-options a:hover {
  color: #0a0a23;
  background-color: var(--nav-green);
  border-radius: 5px;
} */

.nav-wrapper .dropdown-options {
  display: none;
}
.nav-wrapper .dropdown:hover + .dropdown-options,
.nav-wrapper .dropdown-options:hover {
  display: block;
  position: absolute;
}

.toggleMenu {
  position: fixed;
  top: 5vh;
  right: 5vh;
  cursor: pointer;
  font-size: 25px;
}

.toggleMenu svg {
  color: #717171;
}

/* landing */
.landing,
.hero-section {
  position: relative;
  display: table;
  height: 100vh;
  text-align: center;
}

.landing img.bg-img,
.hero-section img.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* opacity: 25%; */
  filter: brightness(0.3);
  z-index: -5;
}

section.nav a,
section.nav .dropdown button {
  color: #898989;
  /* mix-blend-mode: difference; */
}
.home-white-text,
.home-button-white {
  color: #fff;
}
.home-button-white:after {
  background-color: #5d9846 !important;
}

.landing .content,
.hero-section .content {
  display: table-row;
}

.landing .content .content-wrapper,
.hero-section .content .content-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
  height: 100vh;
}

.landing .franchise {
  position: absolute;
  bottom: 0;
  /* display: table-cell; */
  vertical-align: middle;
  /* width: 100vw; */
}

.landing .franchise img {
  /* width: 170px; */
  /* margin: 35px; */
  transition: all 300ms;
}

.landing .franchise img:hover {
  transform: scale(1.2);
}

.franchise-text,
.franchise-outlets {
  display: inline-block;
  /* width: fit-content; */
  min-height: 100vh;
}

.franchise-text {
  display: inline-table;
}

.franchise-text .wrapper {
  display: table-cell;
  vertical-align: middle;
}

.franchise-outlets .wrapper .outlet-img {
  width: 100%;
  /* height: 272px; */
  border-radius: 26.8427px;
  filter: brightness(0.5);
}

.franchise-outlets .wrapper a {
  position: relative;
}

.franchise-outlets .wrapper .outlet-name {
  transition: all 300ms;
  position: absolute;
  width: 65%;
  height: 70%;
  padding: 15px;
  /* background-color: white; */
  z-index: 1;
}

.franchise-outlets .wrapper .outlet-logo {
  margin: auto;
}

.franchise-outlets .wrapper a:hover .outlet-name {
  transform: scale(1.1);
}

/* outlet */

.outlet-home .detail {
  /* width: 63vw; */
  display: block;
  margin: auto;
  padding-bottom: 53px;
}

.outlet-home .bg-outlet {
  filter: opacity(0.1);
  position: absolute;
  z-index: -5;
  width: 100%;
  height: 100vh;
}

/* menu */

.outlet-home .menu {
  padding: 5vh 0;
}

.outlet-home .menu .menu-items a {
  position: relative;
}

.outlet-home .menu .menu-items p {
  position: absolute;
  color: var(--white);
  font-weight: 900;
  /* font-size: 30px; */
  z-index: 12;
  /* width: 210px;
  top: 16vh;
  left: -104px; */
}

.outlet-home .menu .menu-items img.menu-item {
  filter: brightness(0.5);
  transition: all 300ms;
  width: 100%;
  border-radius: 20px;
}

/* for food menu trisara contain */
.outlet-home .menu .menu-items a:hover img.menu-item {
  filter: brightness(0.1);
}

.menu-img img.object-contain {
  object-fit: contain !important;
}
/* book here */

.outlet-home .book-here {
  padding: 113px 0;
  /* background-image: url(../assets/images/book_here.png); */
}

.book-here-text {
  /* width: 562px; */
  display: block;
  margin: 15px auto;
}

/* gallery */

.outlet-home .gallery {
  padding: 100px 0;
}

.outlet-home .gallery img {
  margin: 0 auto;
  max-width: 100%;
}

/* live-music */

/* .outlet-home .live-music {
  padding: 100px;
  background: url(../assets/images/live_music.png);
} */

.outlet-home .live-music .video {
  position: absolute;
  z-index: -100;
  height: 100%;
  width: 100vw;
  object-fit: cover;
  /* opacity: 0.3; */
  /* filter: contrast(0.5); */
}

.outlet-home .live-music .detail {
  width: fit-content;
  display: inline-block;
}

/* scroll animation icon */
.mouse {
  width: 22px;
  height: 33px;
  border: 3px solid #333;
  border-radius: 60px;
  position: relative;
}

.mouse::before {
  content: "";
  width: 3px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 23px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 23px;
  }
}

/* for mobile animated icon */
.scroll {
  width: 33px;
  height: 33px;
  border: 2px solid #333;
  border-radius: 50%;
  position: relative;
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;
}

.scroll::before {
  content: "";
  position: absolute;
  top: 11px;
  left: 11px;
  width: 8px;
  height: 8px;
  border-left: 2px solid #333;
  border-bottom: 2px solid #333;
  transform: rotate(-45deg);
}

@keyframes down {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}

/* toast error fixed */
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="right"] {
  right: 1px;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="left"] {
  left: 1px;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  top: 9.5px;
}

/* toast success fixed */
/* .swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -19.25px;
  left: 5.9375px;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -30.8px;
  left: -4.5px;
} */
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="tip"] {
  top: 14.2px;
  left: 1px;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="long"] {
  top: 11px;
  right: -1px;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5d9846;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #457732;
}

/* CAREERS list page */

/*Careers*/
.search {
  display: flex;
  justify-content: center;
}
.search-bar {
  width: 80vw;
  display: flex;
  border-radius: 4px;
  flex-shrink: 0;
}
.search-bar input {
  background-color: transparent;
  flex: 1;
  padding: 14px 0 14px 20px;
}
.search-bar button {
  border: 0;
  background-color: transparent;
  width: 60px;
  cursor: pointer;
}
.job-container {
  width: 80vw;
  margin: 15px auto;
  display: block;
}
.job-list {
  padding: 20px;
  background-color: var(--nav-green);
  border: var(--green) 1px solid;
  border-radius: 5px;
  transition: all 300ms;
}
.job-list:hover {
  background-color: #d6e9cf;
}
.title {
  display: inline-flex;
  align-items: center;
}

.title svg {
  font-size: 25px;
}
.tags {
  width: 90%;
}
.job-details {
  font-size: 15px;
  border-radius: 5px;
  background: rgb(93 152 70 / 31%);
  display: inline-block;
  align-items: center;
  margin: 10px 10px 10px 0;
  padding: 5px 8px;
}

@media screen and (max-width: 1100px) {
  .search-bar {
    width: 90vw;
  }
  .job-container {
    width: 90vw;
  }
}

@media screen and (min-width: 400px) {
  .btn-apply {
    float: right;
  }
}

/* Careers Desc */
.background-image {
  height: 40vh;
  text-align: center;
  position: relative;
}
.background-image img {
  width: 100vw;
  height: 40vh;
  object-fit: cover;
  position: absolute;
  opacity: 0.25;
  z-index: -1;
}
/* .background-image h1 {
  text-align: center;
  font-weight: 600;
  padding-top: 17vh;
} */
.info-box {
  width: 80vw;
  /* margin: 50px auto; */
  background-color: var(--nav-green);
  border: var(--green) 1px solid;
  border-radius: 5px;
}
.description {
  margin: 20px auto;
  box-sizing: border-box;
  width: 80vw;
  background-color: var(--nav-green);
  border: var(--green) 1px solid;
  border-radius: 5px;
}

/* menu page */
@media only screen and (min-width: 1000px) {
  .menu-categories,
  .menu-subcategories {
    scroll-margin-top: 160px !important;
  }
}
.menu-categories,
.menu-subcategories {
  scroll-margin-top: 90px;
}

.menu-image {
  display: none;
}
@media only screen and (min-width: 2000px) {
  .menu-image {
    display: block;
    width: 42vh;
    position: fixed;
    /* z-index: 0; */
  }
  .menu-image-pos-1 {
    top: 0;
    left: -14vw;
    transform: rotate(-39deg);
  }
  .menu-image-pos-2 {
    top: 16vw;
    left: -13vw;
    transform: rotate(-39deg);
  }
  .logo-text {
    font-size: 80px;
    text-transform: uppercase;
    /* margin: -17px; */
    font-weight: 100;
  }
  h1,
  h2 {
    font-size: 65px;
  }
  p,
  a,
  section.nav .dropdown button,
  button a {
    font-size: 45px;
  }
  .outlet-menu {
    font-size: 85px;
  }
  .home-button-white {
    font-size: 45px;
  }
  section.nav a.mt-4.font-bold img {
    height: 5rem;
  }
  .outlet-home .gallery img {
    height: 38vh;
  }
  .mouse {
    width: 60px;
    height: 90px;
    border: 3px solid #333;
    border-radius: 60px;
    position: relative;
  }
  .mouse::before {
    width: 5px;
    height: 20px;
  }
  .titles img {
    width: 530px;
    max-width: 75%;
  }
  .titles p {
    font-size: 30px;
  }
  div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 70px;
  }
  div:where(.swal2-container) div:where(.swal2-popup) {
    width: 125em !important;
  }
  div:where(.swal2-container) button:where(.swal2-close) {
    font-size: 4.5em;
  }
  /* .grid-item:hover .titles {
    height: 380px;
  } */
  section.nav a.mt-4.font-bold img {
    height: 7rem;
    padding: 5px 50px;
  }
}
.menu-image-pos-3 {
  top: 35vw;
  left: -17vw;
}

.menu-image-2 {
  display: none;
}
@media only screen and (min-width: 2000px) {
  .menu-image-2 {
    display: block;
    width: 42vh;
    position: fixed;
    /* z-index: 0; */
  }
  .menu-image-position-1 {
    top: -3vw;
    right: -17vw;
  }
  .menu-image-position-2 {
    top: 14vw;
    right: -17vw;
  }
}
.menu-image-position-3 {
  top: 35vw;
  right: -17vw;
}

/* menu item desc first letter capital */
.item-description::first-letter {
  text-transform: uppercase;
}

/* loader */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader .heading {
  color: black;
  letter-spacing: 0.2em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.loader .loading {
  display: flex;
  width: 5em;
  align-items: center;
  justify-content: center;
}

.loader .load {
  width: 23px;
  height: 3px;
  background-color: limegreen;
  animation: 1s move_5011 infinite;
  border-radius: 5px;
  margin: 0.1em;
}

.loader .load:nth-child(1) {
  animation-delay: 0.2s;
}

.loader .load:nth-child(2) {
  animation-delay: 0.4s;
}

.loader .load:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes move_5011 {
  0% {
    width: 0.2em;
  }

  25% {
    width: 0.7em;
  }

  50% {
    width: 1.5em;
  }

  100% {
    width: 0.2em;
  }
}

/* pop up width */
.swal2-popup.swal2-modal.swal2-show {
  width: fit-content !important;
  /* min-width: 40vw; */
  max-height: 98.25vh;
}

div#swal2-html-container {
  margin: 1px !important;
}

/* menu image */
span.lazy-load-image-background.lazy-load-image-loaded {
  width: 100%;
}